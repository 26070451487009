/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;

@mixin card() {
  .mat-mdc-card {
    overflow-y: hidden;
    transition: box-shadow 300ms var(--mat-sys-motion-emphasized-decelerate);

    @include mat.card-overrides(
      (
        outlined-container-color: var(--mat-sys-surface-container-low),
      )
    );

    &:has(> table) {
      border-radius: var(--mat-sys-corner-extra-small);
    }

    > a {
      border-radius: inherit;
    }
  }

  .mat-mdc-card-actions {
    justify-content: end;
    gap: 0.5rem;
    flex-direction: row-reverse;
  }

  .mat-mdc-card-header-text {
    overflow: hidden;
  }

  .mat-mdc-card-image {
    &.placeholder {
      background-color: var(--mat-sys-surface-container-highest);
    }

    &:first-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }

  .grid > .mat-mdc-card:hover {
    box-shadow: var(--mat-sys-level1);
  }
}
